body, html, #root, main, .main-row {
  height: 100%;
}

.loading-container {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.content-default {
  padding: 16px;
}
.content-plan {
  display: flex;
  flex-direction: column;
  /* iOS related settings */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-grow: 1;
  /* height: 100%; */
}

.content {
  display: flex;
  flex-direction: column;
  /* iOS related settings */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.sidebar .menubar {
  margin-left: 15px;
  margin-top: 15px;
}

.menubar .nav-link {
  display: flex;
  align-items: center;
}
.menubar .nav-link span {
  margin-left: .5em;
}
.plan-container {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  /* iOS related settings */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;

  svg {
    flex-grow: 1;
    cursor: grab;

    .note-handle {
      fill: #69b3a2;
      cursor: pointer;
    }
  }

  .tooltip {
    position: absolute;
    transform: translate(0%, -50%);

    color: white;
    background-color: black;
    border-radius: 0.2em;
    padding: .2em;
  }

  .plan-actions {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
  }
}
